* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body{
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e1eeff;
  padding: 15px;
}

.container{
  max-width: 450px;
  width: 100%;
  padding: 30px 20px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  background: #fefbdf;
  border-radius: 10px;
  touch-action: none;
  -ms-touch-action: none;
}

.container .card{
  width: 100%;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 2px 20px 30px rgba(0, 0, 0, 0.08);
  cursor: grab;
  user-select: none;
  overflow: hidden;
}

.card .img-container{
  width: 90px;
  height: 100%;
  background: #bfdbfe;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  pointer-events: none;
}

.img-container img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.card .box{
  flex: 1;
  padding: 0 10px;
}

.card .box h4{
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 400;
  color: #797979;
}

.card .box h2{
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
  color: #444;
}


.container .dragging{
  pointer-events: none;
  touch-action: none;
  -ms-touch-action: none;
  box-shadow: 2px 20px 30px rgba(0, 0, 0, 0.2);
}